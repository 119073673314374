import React from 'react'
import { PageProps } from 'gatsby'
import Layout from '@/components/layout'
import { SEO } from '@/components/seo'
import H3 from '@/components/H3'
import H4 from '@/components/H4'
import { OpeningHours } from '@/components/OpeningHours'
import Timetable from '@/components/entrance/Timetable'
import ImageContact from '@/components/ImageContact'
import TelephoneLink from '@/components/TelephoneLink'
import Schedule from '@/components/Schedule'
import TimetableSupplement from '../../components/entrance/TimetableSupplement'

const title = '入所案内'
const timetable = {
  childDevelopment: [
    { hm: '10:00', tasks: ['準備 ※', '朝の会', '♫ お集まり〜'] },
    { hm: '10:30', tasks: ['設定保育'] },
    {
      hm: '11:30',
      tasks: [
        'お片付け',
        'トイレ・片づけ',
        'お弁当準備',
        '手遊び',
        'お弁当',
        '♫ いただきます',
        '絵本（各自）',
        '個別療育',
        '自由遊び',
      ],
    },
    { hm: '13:40', tasks: ['お片付け', 'トイレ・片づけ', 'お帰りの準備', '♫ お帰りの歌', 'ごあいさつ'] },
  ],
  afterSchool: {
    weekday: [
      { hm: '13:00', tasks: ['到着', '準備 ※'] },
      { hm: '15:00', tasks: ['学校課題'] },
      { hm: '15:30', tasks: ['おやつ'] },
      { hm: '17:45', tasks: ['制作・自由時間', '帰りの準備'] },
    ],
    holiday: [
      { hm: '10:00', tasks: ['準備 ※'] },
      { hm: '10:15', tasks: ['課題'] },
      { hm: '12:00', tasks: ['昼食'] },
      { hm: '13:00', tasks: ['個別課題'] },
      { hm: '15:00', tasks: ['おやつ'] },
      { hm: '15:30', tasks: ['自由時間'] },
      { hm: '17:45', tasks: ['帰りの準備'] },
    ],
  },
}

const Entrance = (props: PageProps) => (
  <Layout title={title}>
    <SEO title={title} pathname={props.location.pathname} />
    <div className="text-md md:text-lg">
      <section className="openinghourse mb-16">
        <H3 text="サービス時間" />
        <Schedule />

        <div className="flex flex-wrap">
          <div className="w-full md:w-1/3 mt-3 mb-6 pr-0 md:pr-2 md:mt-0 md:mb-0">
            <OpeningHours
              title="児童発達支援（未就学児）"
              weekday={
                <>
                  10:00-14:00
                  <br />
                  送迎あり
                </>
              }
              holiday={null}
            />
          </div>
          <div className="w-full md:w-2/3 mb-6 pl-0 md:pl-2 md:mb-0">
            <OpeningHours
              title="放課後等デイサービス（就学児）"
              weekday={
                <>
                  ※14:00 - 18:00
                  <br />
                  送迎あり
                </>
              }
              holiday={
                <>
                  10:00 - 18:00
                  <br />
                  送迎あり
                </>
              }
            />
            <p className="text-sm">※ お子様の帰宅時間に合わせて対応いたします。</p>
          </div>
          <div className="w-full mt-6">
            <p>ご見学・ご相談も受け付けております。</p>
            <p>
              お問い合わせは
              {` `}
              <TelephoneLink prefix="TEL: " />
            </p>
            <p>
              <small>（対応時間 10:00 ~ 18:00）</small>
            </p>
          </div>
        </div>
      </section>
      <section className="timetable-childdevelopment mb-16">
        <H3 text="デイリープログラム（児童発達支援）" />
        <p className="mb-4">
          療育と共に、生活習慣や自分でする力を伸ばせるように、準備や片付けの時間を多めにとり、一人ひとりのペースに寄り添いサポートします。
          <br />
          <small>（プログラムは一例です。）</small>
        </p>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 pr-0 md:pr-3">
            <Timetable timeline={timetable.childDevelopment} />
          </div>
          <TimetableSupplement className="mb-4" />
        </div>
      </section>
      <section className="timetable-afterschool mb-16">
        <H3 text="デイリープログラム（放課後等デイサービス）" />
        <p className="mb-4">
          学校課題を中心に、体操・工作・音楽・読み聞かせ・課外活動を取り入れ、一人ひとりの持っている力を伸ばせるようにサポートします。
          <br />
          <small>（プログラムは一例です。）</small>
        </p>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 mb-4 md:mb-0 pr-0 md:pr-3">
            <Timetable title="放課後プログラム" timeline={timetable.afterSchool.weekday} />
          </div>
          <div className="w-full md:w-1/2 pl-0 md:pl-3">
            <Timetable title="土曜日・学校休校日" timeline={timetable.afterSchool.holiday} />
          </div>
          <TimetableSupplement className="mb-4" />
        </div>
      </section>
      <section className="notes mb-16">
        <H3 text="事業所ご利用にあたって" />
        <p className="mb-1">
          ・ご利用にあたり受給者証が必要になります。お住まいの市、区、町の役所にて申請してください。
        </p>
        <p className="mb-1">・児童発達支援、放課後等デイサービスの支給決定を受けている方が対象になります。</p>
        <p className="mb-2">・児童福祉法、障害者総合支援法に基づく利用料金の1割の料金をいただきます。</p>
        <p className="text-sm">※ ただし、市が定める月額上限額の範囲内</p>
      </section>
      <section className="holiday mb-16">
        <H3 text="休業日" />
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 pr-0 md:pr-3 mb-4 md:mb-0">
            <H4 text="児童発達支援" />
            <p className="mb-1">・土日祝</p>
            <p className="mb-1">・お盆（8月13〜15日）</p>
            <p className="mb-1">・年末年始（12月30〜1月3日）</p>
            <p>・天候等による休校日</p>
          </div>
          <div className="w-full md:w-1/2 pl-0 md:pl-3">
            <H4 text="放課後等デイサービス" />
            <p className="mb-1">・第2・4土曜日</p>
            <p className="mb-1">・日曜日、祝日</p>
            <p className="mb-1">・お盆（8月13〜15日）</p>
            <p className="mb-1">・年末年始（12月30〜1月3日）</p>
            <p>・天候等による休校日</p>
          </div>
        </div>
      </section>
      <section className="capacity mb-16">
        <H3 text="定員" />
        <p className="mb-1">
          児童発達支援・放課後等デイサービス合わせて<b>10名/日</b>です。
        </p>
        <p className="text-sm">※ 定員になり次第、締め切らせていただきます。</p>
      </section>
      <section className="lunch mb-16">
        <H3 text="昼食" />
        <p className="mb-1">給食弁当をご用意させていただきます。（実費450円：税込）</p>
        <p>食物アレルギー等のお子様には、お弁当ご持参でお願い致します。</p>
      </section>

      <section className="contact mb-16">
        <ImageContact className="w-auto mx-auto" style={{ maxHeight: '125px' }} />
      </section>
    </div>
  </Layout>
)

export default Entrance
