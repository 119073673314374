import React from 'react'
import { WORKDAYS_AFTER_SCHOOL, WEEKDAYS, WORKDAYS_CHILD_DEVELOPMENT } from '@/constants'
import classNames from 'classnames'

interface Props {}

const Schedule: React.FC<Props> = () => {
  const border = 'border-2 border-l-0 border-t-0 border-b-0 border-purple-300'
  return (
    <>
      <div className="flex mb-5 px-0 md:px-5">
        <table className="w-full text-lg table-auto">
          <thead>
            <tr className="h-auto md:h-16 border-2 border-l-2 md:border-l-0 border-r-0 border-purple-300">
              <th className={classNames(border, 'w-64')}> </th>
              {WEEKDAYS.map((week, index) => (
                <th className={classNames(border, 'w-20')} key={index}>
                  {week}
                </th>
              ))}
              <th className="hidden md:table-cell py-5"> </th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-auto md:h-16 border-2 border-l-2 md:border-l-0 border-r-0 border-purple-300">
              <th className={`${border} text-sm md:text-lg w-64`}>
                児童
                <br className="block sm:hidden" />
                発達
              </th>
              {WORKDAYS_CHILD_DEVELOPMENT.map((time, index) => (
                <th className={classNames(border, 'text-sm md:text-xl w-20 whitespace-pre-wrap')} key={index}>
                  {time.replace(/ /g, '\n')}
                </th>
              ))}
              <th className="hidden md:table-cell py-5 px-2"> </th>
            </tr>
            <tr className="h-auto md:h-16 border-2 border-l-2 md:border-l-0 border-r-0 border-purple-300">
              <th className={classNames(border, 'text-sm md:text-lg w-64')}>
                放課後
                <br className="block sm:hidden" />
                デイ
              </th>
              {WORKDAYS_AFTER_SCHOOL.map((time, index) => (
                <th className={classNames(border, 'text-sm md:text-xl w-20 whitespace-pre-wrap')} key={index}>
                  {time.replace(/ /g, '\n')}
                </th>
              ))}
              <th className="hidden md:table-cell py-5 px-2"> </th>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Schedule
