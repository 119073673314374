import React from 'react'

interface Props {
  text: string
}

const H4: React.FC<Props> = ({ text }) => (
  <h4 className="pt-1 pb-1 mb-2 border border-b-1 border-t-0 border-l-0 border-r-0 border-purple-300">
    <div className="ml-3 font-bold">{text}</div>
  </h4>
)

H4.defaultProps = {}

export default H4
