import React from 'react'

interface Props {
  className: string
}

const TimetableSupplement: React.FC<Props> = (props: Props) => (
  <div className={props.className}>
    <p className="text-sm">
      ※ 準備: 健康チェック（視診・検温）、靴を並べる、連絡帳を出す、お弁当を出す、鞄を片付ける、トイレ・手洗い
    </p>
  </div>
)
export default TimetableSupplement
