import React from 'react'

interface Props {
  title: string
  weekday: React.ReactNode
  holiday: React.ReactNode | null
}

export const OpeningHours: React.FC<Props> = ({ title, weekday, holiday }) => (
  <>
    <h4 className="pt-1 pb-1 mb-3 text-center">
      <span className="font-bold">{title}</span>
    </h4>
    <div className="flex mb-5">
      <table className="w-full text-lg table-auto">
        <thead>
          <tr className="h-auto">
            <th className="border-b-2 border-purple-300 w-1/2 py-2 text-sm md:text-md">平日</th>
            {holiday !== null && (
              <th className="border-b-2 border-l-2 border-purple-300 w-1/2 py-2 text-sm md:text-md">
                土曜（第１・３・５）・長期休暇
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr className="h-auto">
            <th className="border-purple-300 w-1/2 py-2 text-sm md:text-md font-normal">{weekday}</th>
            {holiday !== null && (
              <th className="border-l-2 border-purple-300 w-1/2 py-2 text-sm md:text-md font-normal">{holiday}</th>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  </>
)
