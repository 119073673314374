import React from 'react'

interface Props {
  title?: string
  timeline: {
    hm: string
    tasks: string[]
  }[]
}

const Timetable: React.FC<Props> = ({ title, timeline }) => (
  <>
    {title !== '' && (
      <h4 className="text-xl md:text-lg pt-1 pb-1 text-center border-b border-purple-300">
        <span className="font-bold">{title}</span>
      </h4>
    )}
    <h4 className="pt-1 pb-1 mb-4 text-center border-b-2 border-purple-300">
      <span className="font-bold">送迎</span>
    </h4>
    {timeline.map(({ hm, tasks }) => (
      <div className="flex mb-4 text-left" key={hm}>
        <div className="w-1/3 md:w-1/2 pr-3 font-bold text-right">{hm}</div>
        <div className="w-2/3 md:w-1/2 pl-3">
          {tasks.map((task) => (
            <p key={`${hm} - ${task}`}>{task}</p>
          ))}
        </div>
      </div>
    ))}
    <h4 className="pt-1 pb-1 mb-4 text-center border-t-2 border-purple-300">
      <span className="font-bold">送迎</span>
    </h4>
  </>
)

Timetable.defaultProps = {
  title: '',
}

export default Timetable
